import React from 'react';
import './HeroText.scss';

function HeroText({
    header,
    firstParagraph,
    secondParagraph,
    additionalClasses = [],
    buttonText1,
    buttonLink1,
    buttonText2,
    buttonLink2,
}) {
    return (
        <section className={['hero-text-section', ...additionalClasses].join(' ')}>
            <h1 className="hero-text-section__header">{header}</h1>
            <div className="hero-text-section__description">
                <div className="description-paragraph">{firstParagraph && <p>{firstParagraph}</p>}</div>
                <div className="description-paragraph">{secondParagraph && <p>{secondParagraph}</p>}</div>
                {buttonText1 && (
                    <a href={buttonLink1} className="btn btn--borderless">
                        {buttonText1}
                    </a>
                )}
                {buttonText2 && (
                    <a href={buttonLink2} className="btn btn--borderless">
                        {buttonText2}
                    </a>
                )}
            </div>
        </section>
    );
}

export default HeroText;
